<template>
<div class="od-distribution-amount-pie-chart" v-loading="pieLoading" :id="chartId" />
</template>

<script>
// 统计分析 - 订单金额统计 - 饼图
import { useStore } from 'vuex'
import { computed, inject, onMounted, reactive, toRefs } from 'vue';
import { getDeviceOrderTrend, getDeviceOrderTrendExceptPro } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  props: {
    param: { type: Object }
  },
  setup(props, ctx) {
    const store = useStore();
    const echart = inject("echart");
    const state = reactive({
      chartId: computed(() => store.getters.UUID),
      chartData: null,
      pieLoading: false,
      level: 1,
      agent_id: 0,
      pieCurrentData: null // 临时变量
    });

    onMounted(() => {
      // getData(null);
      window.onresize = () => {
        initChart();
      }
    });

    // 获取数据
    const getData = (data) => {
      state.pieLoading = true;
      let level = data == null ? state.level : data.level;
      // 省代 和其他合伙人 获取数据的接口区分开
      if (level == 1) {
        let param = { device_type: null, int_agent_leve: 1, areas_name: "", dt_from: "", dt_to: "" };
        if (data != null) {
          param = {
            device_type: data.deviceType,
            int_agent_leve: data.level,
            areas_name: data.areas_name,
            dt_from: data.dateRange ? data.dateRange[0] : null,
            dt_to: data.dateRange ? data.dateRange[1] : null
          }
        } else {
          param = {
            device_type: props.param.deviceType,
            int_agent_leve: state.level,
            areas_name: props.param.areas_name,
            dt_from: props.param.dateRange ? props.param.dateRange[0] : null,
            dt_to: props.param.dateRange ? props.param.dateRange[1] : null
          };
        }
        
        // 全权限的人 不需要传地区 加载全部数据
        // 去掉null值的键值对
        for (let i = 0; i < Object.keys(param).length; i++) {
          let key = Object.keys(param)[i];
          if (param[key] == null) {
            delete param[key];
            i--;
          }
        }
        
        state.pieCurrentData = param;
        getDeviceOrderTrend(param).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              ctx.emit("showNull", false);
              if (state.level != 1) {
                ctx.emit("showBtn", true);
              } else {
                ctx.emit("showBtn", false);
              }
              ctx.emit("pieData", state.chartData);

              initChart();
            } else {
              state.chartData = null;
              ctx.emit("showNull", true);
              echart.init(document.getElementById(state.chartId)).dispose();
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.pieLoading = false;
        })
      } else {
        let param = { device_type: null, pagent_id: 0, dt_from: "", dt_to: "" };
        if (data != null) {
          param = {
            device_type: data.deviceType,
            pagent_id: data.t_agent_id,
            dt_from: data.dateRange ? data.dateRange[0] : null,
            dt_to: data.dateRange ? data.dateRange[1] : null
          }
        } else {
          param = {
            device_type: props.param.deviceType,
            pagent_id: props.param.t_agent_id,
            dt_from: props.param.dateRange ? props.param.dateRange[0] : null,
            dt_to: props.param.dateRange ? props.param.dateRange[1] : null
          };
        }

        // 全权限的人 不需要传地区 加载全部数据
        // 去掉null值的键值对
        for (let i = 0; i < Object.keys(param).length; i++) {
          let key = Object.keys(param)[i];
          if (param[key] == null) {
            delete param[key];
            i--;
          }
        }

        state.pieCurrentData = param;
        getDeviceOrderTrendExceptPro(param).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              ctx.emit("showNull", false);
              if (state.level != 1) {
                ctx.emit("showBtn", true);
              } else {
                ctx.emit("showBtn", false);
              }
              ctx.emit("pieData", state.chartData);

              initChart();
            } else {
              state.chartData = null;
              ctx.emit("showNull", true);
              echart.init(document.getElementById(state.chartId)).dispose();
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.pieLoading = false;
        })
      }
    };

    // 初始化图表
    const initChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let pieChart = echart.init(document.getElementById(state.chartId));
      let totalOrder = state.chartData.reduce((a, b) => a + parseFloat(b.sum_order_amount), 0);
      let totalProfit = state.chartData.reduce((a, b) => a + parseFloat(b.sum_order_profit), 0);
      let titleText = "";
      if (state.level == 1) titleText = "省级合伙人";
      else if (state.level == 2) titleText = "市级合伙人";
      else if (state.level == 3) titleText = "市场专员";
      else titleText = "诊所";
      pieChart.setOption({
        title: { text: titleText + ' - 收入合计：' + totalOrder.toFixed(2) + '元（利润合计：' + totalProfit.toFixed(2) + '元）' },
        tooltip: { 
          trigger: 'item',
          formatter:(param) => {
            let result = "<div class='tooltip-oda-pie'>";
            result += `<p>${param.data.name}</p><p>收入：${param.data.value}元</p><p>利润：${param.data.sum_order_profit}元</p>`
            result += "</div>";
            return result;
          }
        },
        legend: { type: "scroll", orient: 'vertical', left: '8%', top: "10%" },
        series: [{
          name: '',
          type: 'pie',
          radius: '50%',
          data: state.chartData.map(t => {
            return { value: t.sum_order_amount, sum_order_profit: t.sum_order_profit, name: t.txt_partner_name, t_agent_id: t.t_agent_id }
          }),
          label: { formatter: "{b}：{c}" },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });
      if (state.level <= 3) {
        pieChart.on("click", function (params) {
          localStorage.setItem("numOrderPie" + state.level, JSON.stringify(state.pieCurrentData))
          state.level += 1;
          getData({ deviceType: state.pieCurrentData.device_type, t_agent_id: params.data.t_agent_id, dateRange: [state.pieCurrentData.dt_from, state.pieCurrentData.dt_to] });
        })
      }
    };

    return {
      ...toRefs(state),
      initChart,
      getData
    }
  }
}
</script>

<style lang="scss" scoped>
.od-distribution-amount-pie-chart {
  height: 100%;
  width: 100%;

  /deep/ .tooltip-oda-pie {
    p {
      margin: 6px 0;
    }
  }
}
</style>
